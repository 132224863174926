import jwtDecode from 'jwt-decode';
import axios from '../utils/axios';
import { AuthenticationApi } from '@bmw-forms/microservice-clients/dist/codegen/api';
import { APIHelper } from '../utils/api.helper';

class AuthService {
	authApi: AuthenticationApi;
	constructor() {
		this.authApi = new AuthenticationApi();
	}

	setAxiosInterceptors = ({ onLogout }) => {
		axios.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error.response && error.response.status === 401) {
					this.setSession(null);

					if (onLogout) {
						onLogout();
					}
				}

				return Promise.reject(error);
			},
		);
	};

	handleAuthentication() {
		const accessToken = this.getAccessToken();
		if (!accessToken) {
			return;
		}
		if (this.isValidToken(accessToken)) {
			this.setSession(accessToken);
		} else {
			this.setSession(null);
		}
	}

	loginWithEmailAndPassword = async (email: string, password: string) => {
		const user = await this.authApi
			.login({ email, password })
			.then(APIHelper.handleResponse)
			.catch(APIHelper.handleExceptions);
		if (user.success) {
			this.setSession(user.access_token);
		}
		return user;
	};

	loginInWithToken = async () => {
		const accessToken = this.getAccessToken();
		if (!accessToken) {
			return;
		}
		return await this.authApi
			.getAccount({ token: accessToken })
			.then(APIHelper.handleResponse)
			.catch(APIHelper.handleExceptions);
	};

	logout = async () => {
		const authenticatedApi = APIHelper.addToken(new AuthenticationApi());
		await authenticatedApi.logout()
			.then(APIHelper.handleResponse)
			.catch(APIHelper.handleExceptions);
		this.setSession(null);
		window.location.reload();
	};

	setSession = (accessToken: string) => {
		if (accessToken) {
			localStorage.setItem('accessToken', accessToken);
		} else {
			localStorage.removeItem('accessToken');
		}
	};

	getAccessToken = () => localStorage.getItem('accessToken');

	/**
	 * Validate token expiry
	 *
	 * @param accessToken 	Token
	 */
	isValidToken = (accessToken) => {
		if (!accessToken) {
			return false;
		}
		const decoded = jwtDecode(accessToken);
		const currentTime = Date.now() / 1000;
		return decoded.exp > currentTime;
	};

	isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
