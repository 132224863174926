import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import PropTypes from 'prop-types';
import React from 'react';
import { API_BASE_URL } from '../config';

function GraphqlWrapper({ children }) {
	const httpLink = createHttpLink({
		uri: API_BASE_URL + '/graphql',
	});

	const authLink = setContext((_, { headers }) => {
		// get the authentication token from local storage if it exists
		const token = localStorage.getItem('accessToken');
		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			},
		};
	});

	const client = new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache({
			typePolicies: {
				Query: {
					fields: {
						servicePackages: relayStylePagination(),
					},
				},
			},
		}),
	});
	return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

GraphqlWrapper.propTypes = {
	children: PropTypes.any,
};

export default GraphqlWrapper;
