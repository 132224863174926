import mock from "../utils/mock";

mock.onGet("/api/categories").reply(200, {
  categories: [
    {
      id: "1",
      name: "Sports",
      sortOrder: "1",
      iframeSeriesParam: "SP",
    },
    {
      id: "2",
      name: "Tour",
      sortOrder: "2",
      iframeSeriesParam: "TR",
    },
  ],
});

mock.onGet("/api/categories/1").reply(200, {
  contact: {
    id: "2",
    name: "Tour",
    sortOrder: "tour",
    iframeSeriesParam: "TR",
  },
});
