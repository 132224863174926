export const UPDATE_MODE_FILTER = '@model/update-filter';

export type ModelFilterDto = {
	liveOnly: boolean;
	testRideForm: boolean;
	eBrochureForm: boolean;
	query: string;
};
export function updateModelFilter(props: ModelFilterDto) {
	return async (dispatch: any) => {
		dispatch({
			type: UPDATE_MODE_FILTER,
			payload: {
				...props,
			},
		});
	};
}
