import axios from '../utils/axios';
import authService from '../services/authService';
import { Dispatch } from 'redux';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';

export function login(email: string, password: string) {
	return async (dispatch: Dispatch) => {
		try {
			dispatch({ type: LOGIN_REQUEST });
			const user = await authService.loginWithEmailAndPassword(email, password);
			if (user.success) {
				dispatch({
					type: LOGIN_SUCCESS,
					payload: {
						user,
					},
				});
			} else {
				dispatch({ type: LOGIN_FAILURE });
				// eslint-disable-next-line
				if(user.message) throw user.message;
				// eslint-disable-next-line
				throw 'Invalid user credentials';
			}
		} catch (error) {
			dispatch({ type: LOGIN_FAILURE });
			// eslint-disable-next-line
			throw error;
		}
	};
}

export function setUserData(user) {
	return (dispatch: Dispatch) =>
		dispatch({
			type: SILENT_LOGIN,
			payload: {
				user,
			},
		});
}

export function logout() {
	return async (dispatch: Dispatch) => {
		authService.logout();

		dispatch({
			type: LOGOUT,
		});
	};
}

export function register() {
	return true;
}

export function updateProfile(update) {
	const request = axios.post('/api/account/profile', { update });

	return (dispatch: Dispatch) => {
		request.then((response) =>
			dispatch({
				type: UPDATE_PROFILE,
				payload: response.data,
			}),
		);
	};
}
