/* eslint-disable react/no-array-index-key */
import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './layouts/DashboardLayout';
import Auth from './components/Auth';
import GraphqlWrapper from './components/Graphql-wrapper';

const routesConfig = [
	{
		exact: true,
		path: '/',
		component: lazy(() => import('./DefaultRouteRedirector')),
	},

	{
		exact: true,
		path: '/404',
		component: lazy(() => import('./views/pages/Error404View')),
	},
	{
		exact: true,
		guard: GuestGuard,
		path: '/login',
		component: lazy(() => import('./views/auth/LoginView')),
	},
	{
		exact: true,
		path: '/login-unprotected',
		component: lazy(() => import('./views/auth/LoginView')),
	},
	{
		exact: true,
		guard: GuestGuard,
		path: '/register',
		component: lazy(() => import('./views/auth/RegisterView')),
	},
	{
		exact: true,
		path: '/register-unprotected',
		component: lazy(() => import('./views/auth/RegisterView')),
	},
	{
		exact: true,
		path: '/lead-form/:formSlug',
		component: lazy(() => import('./views/public-front/lead')),
	},
	{
		exact: true,
		path: '/dealership-locator',
		component: lazy(() => import('./views/public-front/dealer-locator')),
	},
	{
		exact: true,
		path: '/bsi',
		bootstrap: GraphqlWrapper,
		component: lazy(() => import('./views/public-front/bsi')),
	},
	{
		path: '/',
		bootstrap: Auth,
		guard: AuthGuard,
		layout: DashboardLayout,
		routes: [
			{
				exact: true,
				path: '/categories',
				component: lazy(() => import('./views/model-categories/list')),
			},
			{
				exact: true,
				path: '/categories/new',
				component: lazy(() => import('./views/model-categories/new')),
			},
			{
				exact: true,
				path: '/dealerships',
				component: lazy(() => import('./views/dealership/list')),
			},
			{
				exact: true,
				path: '/dealerships/new',
				component: lazy(() => import('./views/dealership/new')),
			},
			{
				exact: true,
				path: '/dealerships/:dealershipId/edit',
				component: lazy(() => import('./views/dealership/edit')),
			},
			{
				exact: true,
				path: '/forms/:formType',
				component: lazy(() => import('./views/form/edit')),
			},
			{
				exact: true,
				path: '/models',
				component: lazy(() => import('./views/models/list')),
			},
			{
				exact: true,
				bootstrap: GraphqlWrapper,
				path: '/models/new',
				component: lazy(() => import('./views/models/new')),
			},
			{
				exact: true,
				bootstrap: GraphqlWrapper,
				path: '/models/:modelId/edit',

				component: lazy(() => import('./views/models/edit')),
			},
			{
				exact: true,
				path: '/dealership-services',
				component: lazy(() => import('./views/dealership-services/list')),
			},
			{
				exact: true,
				path: '/dealership-services/new',
				component: lazy(() => import('./views/dealership-services/new')),
			},
			{
				exact: true,
				path: '/dealership-services/:dealershipServiceId/edit',
				component: lazy(() => import('./views/dealership-services/edit')),
			},
			{
				exact: true,
				path: '/service-included/engine-types',
				bootstrap: GraphqlWrapper,
				component: lazy(() => import('./views/engine-type/list/index')),
			},
			{
				exact: true,
				path: '/service-included/engine-types/new',
				bootstrap: GraphqlWrapper,
				component: lazy(() => import('./views/engine-type/new/index')),
			},
			{
				exact: true,
				path: '/service-included/engine-types/:id/edit',
				bootstrap: GraphqlWrapper,
				component: lazy(() => import('./views/engine-type/edit/index')),
			},
			{
				exact: true,
				path: '/service-included/service-packages',
				bootstrap: GraphqlWrapper,
				component: lazy(() => import('./views/service-package/list/index')),
			},
			{
				exact: true,
				path: '/service-included/orders',
				bootstrap: GraphqlWrapper,
				component: lazy(() => import('./views/orders/list/index')),
			},
			{
				exact: true,
				path: '/service-included/orders/:id',
				bootstrap: GraphqlWrapper,
				component: lazy(() => import('./views/orders/view/index')),
			},
			{
				exact: true,
				path: '/service-included/service-packages/new',
				bootstrap: GraphqlWrapper,
				component: lazy(() => import('./views/service-package/new/index')),
			},
			{
				exact: true,
				path: '/service-included/service-packages/:id/edit',
				bootstrap: GraphqlWrapper,
				component: lazy(() => import('./views/service-package/edit/index')),
			},

			{
				exact: true,
				path: '/privacy-policy',
				component: lazy(() => import('./views/privacy-policy')),
			},
			{
				exact: true,
				path: '/categories/:categoryId/edit',
				component: lazy(() => import('./views/model-categories/edit')),
			},
			{
				component: () => <Redirect to="/404" />,
			},
		],
	},
];

const renderRoutes = (routes) =>
	routes ? (
		<Suspense fallback={<LoadingScreen />}>
			<Switch>
				{routes.map((route, i: number) => {
					const Guard = route.guard || Fragment;
					const Layout = route.layout || Fragment;
					const Component = route.component;
					const Bootstrap = route.bootstrap || Fragment;

					return (
						<Route
							key={i}
							path={route.path}
							exact={route.exact}
							render={(props: any) => (
								<Bootstrap>
									<Guard>
										<Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
									</Guard>
								</Bootstrap>
							)}
						/>
					);
				})}
			</Switch>
		</Suspense>
	) : null;

function Routes() {
	return renderRoutes(routesConfig);
}

export default Routes;
