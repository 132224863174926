import accountReducer from './accountReducer';
import { combineReducers } from 'redux';
import lastUpdatedReducer from './lastUpdated';
import modelFilterReducer from './modelFilter';

const rootReducer = combineReducers({
	account: accountReducer,
	lastUpdated: lastUpdatedReducer,
	modelFilter: modelFilterReducer
});

export default rootReducer;
