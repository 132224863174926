export const LAST_UPDATE = '@last-updated';

export function updateComponents() {
	return async (dispatch: any) => {
		dispatch({
			type: LAST_UPDATE,
			payload: {
				lastUpdated: new Date(),
			},
		});
	};
}
