import mock from "../utils/mock";

mock.onGet("/api/forms/test-ride").reply(200, {
  form: {
    name: "Test Ride",
    urlSegment: "test-ride",
    headerImage: "/static/images/covers/bike.jpg",
    spec: [
      {
        identifier: "headingText",
        label: "Header text",
        value: "Book a test ride",
      },
      {
        identifier: "submissionReceviedText",
        label: "Submission received text",
        value: "You're submission has been received.",
      },
      {
        identifier: "thankyouHeadingText",
        label: "Thank you heading text",
        value: "Thank you for booking a test ride. ",
      },
      {
        identifier: "thankyouText",
        label: "Thank you text",
        value: "Please ensure that you bring in your driving license",
      },
    ],
  },
});

mock.onGet("/api/forms/dealer-locator").reply(200, {
  form: {
    name: "Dealer Locator",
    urlSegment: "dealer-locator",
    headerImage: "/static/images/covers/bike.jpg",
    spec: [
      {
        identifier: "headingText",
        label: "Header text",
        value: "Dealer Search",
      },
      {
        identifier: "subHeadingText",
        label: "Sub heading text",
        value: "Find an authorized BMW Motorrad dealer near you",
      },
      {
        identifier: "searchText",
        label: "Search text",
        value: "Search your text to see current offers, services, and events ",
      },
      {
        identifier: "findLocationText",
        label: "Find location",
        value: "Find Location",
      },
    ],
  },
});

mock.onGet("/api/forms/ebrochure-download").reply(200, {
  form: {
    name: "eBrochure Download",
    urlSegment: "ebrochure-download",
    headerImage: "/static/images/covers/bike.jpg",
    spec: [
      {
        identifier: "headingText",
        label: "Header text",
        value: "Download eBrochure",
      },
      {
        identifier: "submissionReceivedHeadingText",
        label: "Submission received heading text",
        value: "eBrochure request submitted",
      },
      {
        identifier: "downloadHeadingText",
        label: "Download heading text",
        value: "Download your eBrochure ",
      },
      {
        identifier: "downloadText",
        label: "Download text",
        value: "",
      },
    ],
  },
});

mock.onGet("/api/forms/register-interest").reply(200, {
  form: {
    name: "Register Interest",
    urlSegment: "register-interest",
    headerImage: "/static/images/covers/bike.jpg",
    spec: [
      {
        identifier: "headingText",
        label: "Header text",
        value: "Book a test ride",
      },
      {
        identifier: "submissionReceviedText",
        label: "Submission received text",
        value: "You're submission has been received.",
      },
      {
        identifier: "thankyouHeadingText",
        label: "Thank you heading text",
        value: "Thank you for booking a test ride. ",
      },
      {
        identifier: "thankyouText",
        label: "Thank you text",
        value: "Please ensure that you bring in your driving license",
      },
    ],
  },
});

mock.onGet("/api/forms/newsletter-subscription").reply(200, {
  form: {
    name: "Newsletter Subscription",
    urlSegment: "newsletter-subscription",
    headerImage: "/static/images/covers/bike.jpg",
    spec: [
      {
        identifier: "headingText",
        label: "Header text",
        value: "Book a test ride",
      },
      {
        identifier: "submissionReceviedText",
        label: "Submission received text",
        value: "You're submission has been received.",
      },
      {
        identifier: "thankyouHeadingText",
        label: "Thank you heading text",
        value: "Thank you for booking a test ride. ",
      },
      {
        identifier: "thankyouText",
        label: "Thank you text",
        value: "Please ensure that you bring in your driving license",
      },
    ],
  },
});

mock.onGet("/api/forms/bespoke-campaign").reply(200, {
  form: {
    name: "Bespoke Campaign",
    urlSegment: "bespoke-campaign",
    headerImage: "/static/images/covers/bike.jpg",
    spec: [
      {
        identifier: "headingText",
        label: "Header text",
        value: "Book a test ride",
      },
      {
        identifier: "submissionReceviedText",
        label: "Submission received text",
        value: "You're submission has been received.",
      },
      {
        identifier: "thankyouHeadingText",
        label: "Thank you heading text",
        value: "Thank you for booking a test ride. ",
      },
      {
        identifier: "thankyouText",
        label: "Thank you text",
        value: "Please ensure that you bring in your driving license",
      },
    ],
  },
});
