import { ApiKeyAuth } from '@bmw-forms/microservice-clients/dist/codegen/api';

export class APIHelper {
	/**
	 * Formats errors into FormIK friendly format
	 *
	 * @param body
	 */
	static formatFieldErrors(body: { statusCode: number; message: Array<any>; error: string }) {
		let errors = {};
		if (body.message && body.message.length > 0) {
			body.message.forEach((error) => {
				if (typeof error === 'object') {
					errors = {
						...errors,
						[error.property]: error.constraints[Object.keys(error.constraints)[0]],
					};
				}
			});
		}
		return errors;
	}

	/**
	 * Handle exceptions
	 *
	 * @param error
	 */
	static handleExceptions(error: any) {
		const {
			response: { body },
		} = error;
		if (body.statusCode === 400 && typeof body.message === 'object') {
			return {
				success: false,
				errors: APIHelper.formatFieldErrors(error.body),
			};
		} else {
			return {
				success: false,
				message: body.message,
			};
		}
	}

	static handleResponse(rawResponse) {
		try {
			const { response } = rawResponse;
			const success = response.statusCode >= 200 && response.statusCode < 300;
			return { success, ...response.body };
		} catch (error) {
			return { success: false, message: 'Service is temporarily down' };
		}
	}

	/**
	 * Add token to API requests
	 *
	 * @param classObject
	 */
	static addToken(classObject: any) {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) {
			return null;
		}
		const apiKeyAuth = new ApiKeyAuth('header', 'Authorization');
		apiKeyAuth.apiKey = 'Bearer ' + accessToken;

		if (typeof classObject.setDefaultAuthentication === 'function') {
			classObject.setDefaultAuthentication(apiKeyAuth);
		}
		return classObject;
	}
}
