/* eslint-disable no-param-reassign */
import produce from 'immer';
import { LAST_UPDATE } from '../actions/lastUpdated';

const initialState = {
	lastUpdated: null,
};

const lastUpdatedReducer = (state = initialState, action) => {
	switch (action.type) {
		case LAST_UPDATE: {
			const { lastUpdated } = action.payload;

			return produce(state, (draft) => {
				draft.lastUpdated = lastUpdated;
			});
		}

		default: {
			return state;
		}
	}
};

export default lastUpdatedReducer;
