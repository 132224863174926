/* eslint-disable no-param-reassign */
import produce from 'immer';
import { UPDATE_MODE_FILTER } from '../actions/modelFilter';

const initialState = {
	query: '',
	liveOnly: false,
	testRideForm: false,
	eBrochureForm: false,
};

const modelFilterReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_MODE_FILTER: {
			const { liveOnly, testRideForm, eBrochureForm, query } = action.payload;

			return produce(state, (draft) => {
				draft.liveOnly = liveOnly;
				draft.testRideForm = testRideForm;
				draft.eBrochureForm = eBrochureForm;
				draft.query = query;
			});
		}

		default: {
			return state;
		}
	}
};

export default modelFilterReducer;
