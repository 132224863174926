/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { Avatar, Box, Divider, Drawer, Hidden, Link, List, ListSubheader, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Activity as ActivityIcon, File as FileIcon, Folder as FolderIcon, MapPin as MapPinIcon, Package as PackageIcon, Settings as GearIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../../components/Logo';
import NavItem from './NavItem';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

const navConfig = [
	{
		subheader: 'Forms',
		items: [
			{
				title: 'Test Ride',
				icon: ActivityIcon,
				href: '/forms/test-ride',
			},
			{
				title: 'eBrochure Download',
				icon: ActivityIcon,
				href: '/forms/ebrochure-download',
			},
			{
				title: 'Dealer Locator',
				icon: ActivityIcon,
				href: '/forms/dealer-locator',
			},
			{
				title: 'Register Interest',
				icon: ActivityIcon,
				href: '/forms/register-interest',
			},
			{
				title: 'Newsletter Subscription',
				icon: ActivityIcon,
				href: '/forms/newsletter-subscription',
			},
		],
	},
	{
		subheader: 'Settings',
		items: [
			{
				title: 'Dealerships',
				icon: MapPinIcon,
				href: '/dealerships',
			},
			{
				title: 'Dealership Services',
				icon: SettingsApplicationsIcon,
				href: '/dealership-services',
			},
			{
				title: 'Models',
				icon: PackageIcon,
				href: '/models',
			},
			{
				title: 'Model Categories',
				icon: FolderIcon,
				href: '/categories',
			},
			{
				title: 'BMW Service Included',
				href: '/service-included',
				icon: GearIcon,
				items: [
					{
						title: 'Manage Packages',
						href: '/service-included/service-packages',
					},
					{
						title: 'Manage Engines',
						href: '/service-included/engine-types',
					},
					{
						title: 'Orders',
						href: '/service-included/orders',
					},
				],
			},
			{
				title: 'Privacy Policy',
				icon: FileIcon,
				href: '/privacy-policy',
			},
		],
	},
];
const pathname = '';
function renderNavItems({ items, ...rest }) {
	return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, pathname, item, ...rest }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
	const key = item.title + depth;

	if (item.items) {
		const open = matchPath(pathname, {
			path: item.href,
			exact: false,
		});

		acc.push(
			<NavItem depth={depth} icon={item.icon} key={key} info={item.info} open={Boolean(open)} title={item.title}>
				{renderNavItems({
					depth: depth + 1,
					pathname,
					items: item.items,
				})}
			</NavItem>,
		);
	} else {
		acc.push(<NavItem open={true} depth={depth} href={item.href} icon={item.icon} key={key} info={item.info} title={item.title} />);
	}

	return acc;
}

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)',
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64,
	},
}));

function NavBar({ openMobile, onMobileClose }) {
	const classes = useStyles();
	const location = useLocation();
	const { user } = useSelector((state: any) => state.account);

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box p={2} display="flex" justifyContent="center">
						<RouterLink to="/">
							<Logo />
						</RouterLink>
					</Box>
				</Hidden>
				<Box p={2}>
					<Box display="flex" justifyContent="center">
						<RouterLink to="/dealerships">
							<Avatar alt="User" className={classes.avatar} src="/static/logo.svg" />
						</RouterLink>
					</Box>
					<Box mt={2} textAlign="center">
						<Link component={RouterLink} to="/dealerships" variant="h5" color="textPrimary" underline="none">
							BMW Motorrad
						</Link>
						<Typography variant="body2" color="textSecondary">
							{`${user.firstname} ${user.lastname}`}
						</Typography>
					</Box>
				</Box>
				<Divider />
				<Box p={2}>
					{navConfig.map((config) => (
						<List
							key={config.subheader}
							subheader={
								<ListSubheader disableGutters disableSticky>
									{config.subheader}
								</ListSubheader>
							}
						>
							{renderNavItems({
								items: config.items,
								pathname: location.pathname,
							})}
						</List>
					))}
				</Box>
			</PerfectScrollbar>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default NavBar;
