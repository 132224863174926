import mock from "../utils/mock";

mock.onGet("/api/dealerships").reply(200, {
  dealerships: [
    {
      id: "1",
      name: "Advantages Motors",
      emailAddress: "advantages@bmw.com.au",
      phone: "03 9123 5231",
      suburb: "Heathmont",
      postcode: "3135",
      addressLineOne: "1 Skyline Place",
      addressLineTwo: "",
      state: "vic",
      iframeOutletParam: "Advantage Motors",
    },
    {
      id: "5e887ac47eed253091be10cb",
      name: "Southbank Motors",
      emailAddress: "advantages@bmw.com.au",
      phone: "03 9123 5231",
      suburb: "Heathmont",
      postcode: "3135",
      addressLineOne: "1 Skyline Place",
      addressLineTwo: "",
      state: "vic",
      iframeOutletParam: "Advantage Motors",
    },
    {
      id: "5e887b209c28ac3dd97f6db5",
      name: "Ringwood Motors",
      emailAddress: "advantages@bmw.com.au",
      phone: "03 9123 5231",
      suburb: "Heathmont",
      postcode: "3135",
      addressLineOne: "1 Skyline Place",
      addressLineTwo: "",
      state: "vic",
      iframeOutletParam: "Advantage Motors",
    },
    {
      id: "5e887b7602bdbc4dbb234b27",
      name: "Heathmont Motors",
      emailAddress: "advantages@bmw.com.au",
      phone: "03 9123 5231",
      suburb: "Heathmont",
      postcode: "3135",
      addressLineOne: "1 Skyline Place",
      addressLineTwo: "",
      state: "vic",
      iframeOutletParam: "Advantage Motors",
    },
    {
      id: "5e86809283e28b96d2d38537",
      name: "Preston Motors",
      emailAddress: "advantages@bmw.com.au",
      phone: "03 9123 5231",
      suburb: "Heathmont",
      postcode: "3135",
      addressLineOne: "1 Skyline Place",
      addressLineTwo: "",
      state: "vic",
      iframeOutletParam: "Advantage Motors",
    },
    {
      id: "5e86805e2bafd54f66cc95c3",
      name: "Richmond Motors",
      emailAddress: "advantages@bmw.com.au",
      phone: "03 9123 5231",
      suburb: "Heathmont",
      postcode: "3135",
      addressLineOne: "1 Skyline Place",
      addressLineTwo: "",
      state: "vic",
      iframeOutletParam: "Advantage Motors",
    },
    {
      id: "5e887a1fbefd7938eea9c981",
      name: "Doncaster Motors",
      emailAddress: "advantages@bmw.com.au",
      phone: "03 9123 5231",
      suburb: "Heathmont",
      postcode: "3135",
      addressLineOne: "1 Skyline Place",
      addressLineTwo: "",
      state: "vic",
      iframeOutletParam: "Advantage Motors",
    },
  ],
});

mock.onGet("/api/dealerships/1").reply(200, {
  contact: {
    name: "Doncaster Motors",
    emailAddress: "advantages@bmw.com.au",
    phone: "03 9123 5231",
    suburb: "Heathmont",
    postcode: "3135",
    addressLineOne: "1 Skyline Place",
    addressLineTwo: "",
    state: "vic",
    iframeOutletParam: "Advantage Motors",
  },
});
